/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';

import { useAuth } from '@context';
import NeedVerifyPass from './needVerifyPass';

const NeedVerify = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
  }, []);
  return <NeedVerifyPass text="Verifique seu e-mail para completar seu cadastro e entrar na plataforma." />;
};

export default NeedVerify;
